import React from 'react';
import { Routes, Route } from 'react-router';
import Public from '../pages/public/public';import Private from '../pages/private/private';

function RoutingApp() {
    return (
        <Routes>
            <Route path="/" element={<Public />} />
<Route path="/Public" element={<Public />} />
<Route path="/Private" element={<Private />} />

        </Routes>
    );

}

export default RoutingApp;
import React from 'react';import { Link } from 'react-router-dom';


import styles from './public.module.css';

/**
 * Public component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Public component.
 */
const Public = () =>{
    
    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Public}>
                
<section custom-agua-item="layer" className={styles.publicbody}   >
<section custom-agua-item="layer" className={styles.publicsection1}   >
<section custom-agua-item="layer" className={styles['layer-242-12ddbac18c30443b8222554a0d72bc2b']}   >
<section custom-agua-item="layer" className={styles['layer-252-b17a48de24a04bb39ec64ee3a6def6a8']}   ></section>
<section custom-agua-item="layer" className={styles['layer-262-d8f2eb50df334b9a957fc4c522e13177']}   >
<section custom-agua-item="layer" className={styles['layer-272-f3c0bca477144f5eaed2f803b7c9bb46']}   >
<p custom-agua-item="text" className={styles['text-12-3617ba94b7804984a009123ea1f84577']}   >Santiago (Santi)</p>
</section>
<section custom-agua-item="layer" className={styles['layer-282-d30c8b572a8e4dc4a0b3f9a347a72423']}   >
<p custom-agua-item="text" className={styles['text-22-1fd0e1afac4a44b693dac70b635d0b1e']}   >Sáenz Ariza
</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.publicsection2}   >
<section custom-agua-item="layer" className={styles['layer-292-b8381290a7c549568c31465f321e6ba0']}   >
<section custom-agua-item="layer" className={styles['layer-352-db573202de0c4891a0ee3d3a3d6a2b64']}   >
<section custom-agua-item="layer" className={styles['layer-362-26d130449d124aa2934e4b9fc3670c59']}   onClick={() => openInNewTab("https://www.agua.app/")}>
<p custom-agua-item="text" className={styles['text-42-e197e7600d924030bc465e97ec1ba7c2']}   >Agua</p>
</section>
<section custom-agua-item="layer" className={styles['layer-372-2a65d42a04934ecbb8c627fa4c579591']}   >
<p custom-agua-item="text" className={styles['text-52-cc690a06f53747fabf820a253f967c00']}   >CEO & Cofounder
</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-302-2b0844082a9e42648cf225a40acde49b']}   >
<section custom-agua-item="layer" className={`${styles['layer-382-46953d2c420e404482c05ab26ebc8997-orientation']} ${styles['layer-382-46953d2c420e404482c05ab26ebc8997']}`}   >
<section custom-agua-item="layer" className={`${styles['layer-1242-a7f8eaddf67642fcbdc6301923d955dc-orientation']} ${styles['layer-1242-a7f8eaddf67642fcbdc6301923d955dc']}`}   >
<section custom-agua-item="layer" className={styles.mail2}   onClick={() => openInNewTab("mailto:santiago@agua.dev")}>
<section custom-agua-item="layer" className={styles['layer-402-3bf762518fec42caa3b28ed20fab35ae']}   ></section>
<section custom-agua-item="layer" className={styles['layer-412-75463fb72d03476e8f8295a3db059018']}   >
<p custom-agua-item="text" className={styles['text-62-2ceae71e03ba4ff9aa8eadbec5d8825a']}   >santiago@agua.dev</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.phone2}   onClick={() => openInNewTab("https://wa.me/573214780277")}>
<section custom-agua-item="layer" className={styles['layer-4042-1cef1058614b404e83f364dabb937c95']}   ></section>
<section custom-agua-item="layer" className={styles['layer-4142-8c42b70f9505488ea3127dc7f4bcce3d']}   >
<p custom-agua-item="text" className={styles['text-642-c4901ebc5d6e450da60ccea3a6854f89']}   >+57 3214780277</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-1252-97964b095baf441daedb35bcc822a301-orientation']} ${styles['layer-1252-97964b095baf441daedb35bcc822a301']}`}   >
<section custom-agua-item="layer" className={styles.meet2}   onClick={() => openInNewTab("https://agua.tools/meetings/santiago-saenz-ariza")}>
<section custom-agua-item="layer" className={styles['layer-4022-c455590ccce442b2baffbf1a5d76b2ea']}   ></section>
<section custom-agua-item="layer" className={styles['layer-4122-6bcc93bb9bb044c880c80da662e52f66']}   >
<p custom-agua-item="text" className={styles['text-622-e4fa8a0d6b714281a0f3ab0ceeacfae7']}   >Book a meeting!</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.linkedin2}   onClick={() => openInNewTab("https://www.linkedin.com/in/santiagosaenzariza/")}>
<section custom-agua-item="layer" className={styles['layer-4032-3ae934746d1a4491aec96be2bb98d31f']}   ></section>
<section custom-agua-item="layer" className={styles['layer-4132-f75f18f4886243edb13720c2c322dcbf']}   >
<p custom-agua-item="text" className={styles['text-632-c72f326bbd424360b738b9416ef4632d']}   >Lets connect!</p>
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-312-135e2c30fa254511a6751da0a0c42599']}   >
<section custom-agua-item="layer" className={styles['layer-322-93572672909f4ed89e020d4d3f5ffebf']}   onClick={() => openInNewTab("https://www.agua.app/")}>
<section custom-agua-item="layer" className={styles['layer-332-5a19d66ca99c4701928319099229badd']}   ></section>
<section custom-agua-item="layer" className={styles['layer-342-b0e3d7b51bb84793a1def1e39f57a0b4']}   >
<p custom-agua-item="text" className={styles['text-32-54d48c57c80248e7ac2bc779632f9207']}   >www.agua.dev</p>
</section>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Public;
            
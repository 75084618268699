import React from 'react';import { Link } from 'react-router-dom';


import styles from './private.module.css';

/**
 * Private component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Private component.
 */
const Private = () =>{
    
    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Private}>
                
<section custom-agua-item="layer" className={styles.privatebody}   >
<section custom-agua-item="layer" className={styles.privatesection1}   >
<section custom-agua-item="layer" className={styles['layer-24-a6e6df8741234e3490167188b142f966']}   >
<section custom-agua-item="layer" className={styles['layer-25-d0b8e308b17346188579d64a3a96c469']}   ></section>
<section custom-agua-item="layer" className={styles['layer-26-cd439b6e5bdd42828dca6fb536a8fb27']}   >
<section custom-agua-item="layer" className={styles['layer-27-de1592cc54004e7ebb9a3ab861644470']}   >
<p custom-agua-item="text" className={styles['text-1-e5b6d808d8e84ce1903a7aec5999b974']}   >Santiago (Santi)</p>
</section>
<section custom-agua-item="layer" className={styles['layer-28-5d23ff5fe1734303a5e6428323637785']}   >
<p custom-agua-item="text" className={styles['text-2-7dad4495fe514f29ba646c08c7ab57a1']}   >Sáenz Ariza
</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.privatesection2}   >
<section custom-agua-item="layer" className={styles['layer-29-dbd81435ae404000aa0f90d91ad7d65b']}   >
<section custom-agua-item="layer" className={styles['layer-35-13b6e86eef2c41ed94f4ef6d235f8521']}   >
<section custom-agua-item="layer" className={styles['layer-36-5c611cb6470747c5867915a35013b181']}   onClick={() => openInNewTab("https://www.agua.app/")}>
<p custom-agua-item="text" className={styles['text-4-7923f3c697924b768cc36edd91f67590']}   >Agua</p>
</section>
<section custom-agua-item="layer" className={styles['layer-37-bbd0788208c94a768fa445b20ac734b8']}   >
<p custom-agua-item="text" className={styles['text-5-0ada14a052f54240860d19bce4932ec8']}   >CEO & Cofounder
</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-30-b24bedfbda4e4428b4b9a55cd212a43c']}   >
<section custom-agua-item="layer" className={`${styles['layer-38-5f35966d62944a78988334c1b3ab077f-orientation']} ${styles['layer-38-5f35966d62944a78988334c1b3ab077f']}`}   >
<section custom-agua-item="layer" className={`${styles['layer-124-7e5b8391c4bd421e91511ddad9916f5f-orientation']} ${styles['layer-124-7e5b8391c4bd421e91511ddad9916f5f']}`}   >
<section custom-agua-item="layer" className={styles.mail}   onClick={() => openInNewTab("mailto:santiago@agua.dev")}>
<section custom-agua-item="layer" className={styles['layer-40-ad0d9b0854f4400bbf37995bb534ff96']}   ></section>
<section custom-agua-item="layer" className={styles['layer-41-6ed1dd102ac04020b8559bc2645664e0']}   >
<p custom-agua-item="text" className={styles['text-6-3e3f878943254a17afc6d0243cec9119']}   >santiago@agua.dev</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.phone}   onClick={() => openInNewTab("https://wa.me/573214780277")}>
<section custom-agua-item="layer" className={styles['layer-404-c24389505394490b842c8538e04d454f']}   ></section>
<section custom-agua-item="layer" className={styles['layer-414-af507c61a5d24efd8e511d5cc29d7667']}   >
<p custom-agua-item="text" className={styles['text-64-544167d4e8c94accaaaaa913a292beff']}   >+57 3214780277</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-125-90bce750d4af480699d3eb57bdaf5d61-orientation']} ${styles['layer-125-90bce750d4af480699d3eb57bdaf5d61']}`}   >
<section custom-agua-item="layer" className={styles.meet}   onClick={() => openInNewTab("https://agua.tools/meetings/santiago-saenz-ariza")}>
<section custom-agua-item="layer" className={styles['layer-402-90dceb1e0b4e468ab6346fdcf69b14fa']}   ></section>
<section custom-agua-item="layer" className={styles['layer-412-0c463b7899c24411b3c4ddc7b63a34dd']}   >
<p custom-agua-item="text" className={styles['text-62-05cc60b3daed4aaf9771913e5392bd93']}   >Book a meeting!</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.linkedin}   onClick={() => openInNewTab("https://www.linkedin.com/in/santiagosaenzariza/")}>
<section custom-agua-item="layer" className={styles['layer-403-87049a8a97c44fefb8e631674d6e4898']}   ></section>
<section custom-agua-item="layer" className={styles['layer-413-9baee724e2a64c9aa2f1e24828feaccc']}   >
<p custom-agua-item="text" className={styles['text-63-8581671c2d974b98ab274ee33279395e']}   >Lets connect!</p>
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-31-ab97b77b6c504cb8891aabfc7636c4ff']}   >
<section custom-agua-item="layer" className={styles['layer-32-3a3ab061982243feac48822fc2f533f3']}   onClick={() => openInNewTab("https://www.agua.app/")}>
<section custom-agua-item="layer" className={styles['layer-33-81aa845643b24afbb0bc3875ec5abd1e']}   ></section>
<section custom-agua-item="layer" className={styles['layer-34-a5fd8b9173d44c518baa86bfec4c3f2e']}   >
<p custom-agua-item="text" className={styles['text-3-debae6e5fcb34585a34cab6968fddb3b']}   >www.agua.dev</p>
</section>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Private;
            